import type { ComponentType } from 'react'
import type { IDisplayCoffeeAmp } from './DisplayCoffeeAmp'
import { useEffect } from 'react'
import { useAmp } from 'next/amp'
import dynamic from 'next/dynamic'
import { mixins, theme } from '../../../styles'

const DisplayCoffeeAmp: ComponentType<IDisplayCoffeeAmp> = dynamic(function DisplayCoffeeAmp() {
  return import('./DisplayCoffeeAmp').then((module) => module.DisplayCoffeeAmp)
})

export interface IDisplayCoffee {
  containerId: string
  positions?: { [key: string]: number }[]
  slot?: { targets: { position: string }; sizes: [number] }
  type?: string
}

// Coffee means Ad, it was renamed to avoid DOM modification by adblockers
export function DisplayCoffee({ containerId, slot, type, positions }: IDisplayCoffee): JSX.Element {
  const isAmp = useAmp()
  if (!containerId) return null

  if (isAmp) {
    return <DisplayCoffeeAmp containerId={containerId} slot={slot} type={type} />
  }

  return <DisplayCoffeeStandard containerId={containerId} positions={positions} />
}

export function DisplayCoffeeStandard({ containerId, positions }: IDisplayCoffee): JSX.Element {
  const hasDisplayForBodyHeaderEnd =
    positions?.findIndex((position) => Object.keys(position).includes('bodyHeaderEnd')) > -1

  useEffect(() => {
    const iframe = document?.getElementById(containerId)?.querySelector('iframe')
    if (iframe) {
      iframe.title = 'Publicité'
      iframe.setAttribute('aria-label', 'Publicité')
    }
  }, [containerId])

  return (
    <>
      <div
        id={containerId}
        className={`DisplayCoffee ${
          hasDisplayForBodyHeaderEnd ? 'DisplayCoffee_BodyHeaderEnd' : ''
        }`}
      />
      <style jsx global>{`
        .DisplayCoffee_BodyHeaderEnd:not(:empty) {
          background-color: var(--color-lightBlue);
          padding: 20px 0;
        }

        .Main__Body .DisplayCoffee:not(:empty) {
          margin-bottom: 50px;
        }

        @media ${mixins.mediaQuery.tabletPaysage} {
          .Main__Body .DisplayCoffee:not(:empty) {
            margin: 0px ${theme.block.marginLR}px 50px;
          }
        }
      `}</style>
    </>
  )
}
